<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="border-bottom">
      <v-tab href="#tab-1">{{ this.$t("generic.lang_general") }}</v-tab>
      <v-tab href="#tab-2">{{ this.$t("generic.lang_tip_dialog") }}</v-tab>
      <v-tab href="#tab-3">
        {{ this.$t("settings.lang_tip_customer_display") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <general-tip-setting></general-tip-setting>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <tip-dialog-setting></tip-dialog-setting>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <customer-display-tip></customer-display-tip>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import GeneralTipSetting from "@/components/settings/moduleSettings/tip-manager/GeneralTipSetting";
import TipDialogSetting from "@/components/settings/moduleSettings/tip-manager/TipDialogSetting";
import CustomerDisplayTip from "@/components/settings/moduleSettings/tip-manager/CustomerDisplayTip";

export default {
  name: "TipManagerComponent",
  components: {
    GeneralTipSetting,
    TipDialogSetting,
    CustomerDisplayTip,
  },
  data: () => ({
    tab: null,
  }),
};
</script>
