<template>
  <div>
    <page-title
      :heading="$t('generic.lang_tip_manager')"
      :subheading="$t('generic.lang_tip_manager')"
      :icon="icon"
      :show-add-button="false"
      :permission-add="true"
    ></page-title>
    <div class="app-main__inner">
      <TipManagerComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../Layout/Components/PageTitle.vue';
import TipManagerComponent from '@/components/settings/moduleSettings/tip-manager/TipManagerComponent.vue';

export default {
  components: {
    TipManagerComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-cash icon-gradient bg-tempting-azure',
  }),
};
</script>
