<template>
  <v-container fluid>
    <v-form
      lazy-validation
      ref="form"
    >
      <v-row class="mx-0">
        <v-col
          v-if="this.loading"
          cols="12"
        >
          <v-progress-linear
            indeterminate
            color="primary"
            height="5"
          ></v-progress-linear>
        </v-col>

        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-checkbox
            v-model="form.disableTip"
            :label="$t('settings.lang_disableRestAsTipInCashout')"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="form.dynamicTipText"
            :label="$t('generic.lang_tipDialogTitle')"
            dense
            :data-layout="KEYBOARD.KEYSETS.NORMAL"
            @focus="showTouchKeyboard"
            outlined
            counter="200"
            :rules="[(v) => dialogTitleRules(v)]"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
          lg="4"
          class="pt-0"
        >
          <v-checkbox
            v-model="form.tipManagementInTablebee"
            :label="$t('generic.lang_enable_tip_management_tablebee')"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          class="px-0"
        >
          <v-row class="mx-0">
            <v-col cols="12">
              <b-tabs>
                <b-tab active>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="DE" />
                    </v-btn>
                  </template>
                  <b-card
                    class="elevation-0 border-right border-left border-bottom pt-0"
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  >
                    <v-row
                      no-gutters
                      class="pt-0"
                    >
                      <v-col
                        class="pt-0 mt-0"
                        cols="12"
                      >
                        <v-subheader
                          ><strong
                            >Tablebee
                            {{ this.$t('generic.lang_tipDialogTitle') }}</strong
                          >
                        </v-subheader>
                        <v-text-field
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          dense
                          outlined
                          class="mb-4"
                          v-model="form.dynamicTipTextTablebee_de"
                          counter="200"
                          :rules="[(v) => dialogTitleRules(v)]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="GB" />
                    </v-btn>
                  </template>
                  <b-card
                    class="elevation-0 border-right border-left border-bottom pt-0"
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  >
                    <v-row
                      no-gutters
                      class="pt-0"
                    >
                      <v-col
                        class="pt-0 mt-0"
                        cols="12"
                      >
                        <v-subheader
                          ><strong
                            >Tablebee
                            {{ this.$t('generic.lang_tipDialogTitle') }}</strong
                          >
                        </v-subheader>
                        <v-text-field
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          dense
                          outlined
                          class="mb-4"
                          v-model="form.dynamicTipTextTablebee_en"
                          counter="200"
                          :rules="[(v) => dialogTitleRules(v)]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="FR" />
                    </v-btn>
                  </template>
                  <b-card
                    class="elevation-0 border-right border-left border-bottom pt-0"
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  >
                    <v-row
                      no-gutters
                      class="pt-0"
                    >
                      <v-col
                        class="pt-0 mt-0"
                        cols="12"
                      >
                        <v-subheader
                          ><strong
                            >Tablebee
                            {{ this.$t('generic.lang_tipDialogTitle') }}</strong
                          >
                        </v-subheader>
                        <v-text-field
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          dense
                          outlined
                          class="mb-4"
                          v-model="form.dynamicTipTextTablebee_fr"
                          counter="200"
                          :rules="[(v) => dialogTitleRules(v)]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn
                      text
                      class="ma-0"
                    >
                      <country-flag country="SA" />
                    </v-btn>
                  </template>
                  <b-card
                    class="elevation-0 border-right border-left border-bottom pt-0"
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  >
                    <v-row
                      no-gutters
                      class="pt-0"
                    >
                      <v-col
                        class="pt-0 mt-0"
                        cols="12"
                      >
                        <v-subheader
                          ><strong
                            >Tablebee
                            {{ this.$t('generic.lang_tipDialogTitle') }}</strong
                          >
                        </v-subheader>
                        <v-text-field
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          dense
                          outlined
                          class="mb-4"
                          v-model="form.dynamicTipTextTablebee_ar"
                          counter="200"
                          :rules="[(v) => dialogTitleRules(v)]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </b-card>
                </b-tab>
              </b-tabs>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          class="text-right"
          cols="12"
        >
          <v-btn
            color="primary"
            class="mx-auto"
            @click="updateGeneralTipSetting"
            :loading="this.saving"
          >
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { Events } from '@/plugins/events';
import { ENDPOINTS } from '@/config';
import mixin from '@/mixins/KeyboardMixIns';
import CountryFlag from 'vue-country-flag';
export default {
  name: 'GeneralTipSetting',
  mixins: [mixin],
  components: {
    CountryFlag,
  },
  data() {
    return {
      ENDPOINTS,
      loading: false,
      saving: false,
      form: {
        disableTip: false,
        dynamicTipText: '',
        tipManagementInTablebee: false,
        dynamicTipTextTablebee_en: '',
        dynamicTipTextTablebee_ar: '',
        dynamicTipTextTablebee_fr: '',
        dynamicTipTextTablebee_de: '',
      },
      // ----------------------------------
    };
  },
  computed: {
    dialogTitleRules() {
      return (v) => {
        if (!v) return true;

        return v && v.length <= 200;
      };
    },
  },
  methods: {
    async getGeneralTipSetting() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.GENERAL_SETTINGS.GET)
        .then((res) => {
          if (res.data.success) {
            this.form = {
              disableTip: res.data.disableTip,
              dynamicTipText: res.data.dynamicTipText,
              tipManagementInTablebee: res.data.tipManagementInTablebee,
              dynamicTipTextTablebee_en:
                res.data.dynamicTipTextTablebee_en ?? '',
              dynamicTipTextTablebee_ar:
                res.data.dynamicTipTextTablebee_ar ?? '',
              dynamicTipTextTablebee_fr:
                res.data.dynamicTipTextTablebee_fr ?? '',
              dynamicTipTextTablebee_de:
                res.data.dynamicTipTextTablebee_de ?? '',
            };
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateGeneralTipSetting() {
      if (!this.$refs.form.validate()) return;
      this.saving = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.GENERAL_SETTINGS.UPDATE,
          this.form
        )
        .then((res) => {
          if (res.data.success) {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_savedSuccessfully'),
              color: 'success',
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
  mounted() {
    this.getGeneralTipSetting();
  },
};
</script>
