<template>
  <v-container fluid>
    <v-row class="mx-0">
      <v-col
        cols="12"
        md="6"
        :class="{
          'border-right': this.$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-row class="mx-0 p-2">
          <v-col
            class="mx-0 px-0 py-1"
            cols="12"
          >
            <v-card-title class="px-0">
              {{ $t('generic.lang_tip_dialog_options') }}
            </v-card-title>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="border"
          >
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 1 '"
              v-model="form.tip_1"
              type="number"
              :min="0"
              :max="100"
              suffix="%"
              hide-details
              :loading="this.loadingTipsMatrix"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="border"
          >
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 2 '"
              v-model="form.tip_2"
              type="number"
              :min="0"
              :max="100"
              suffix="%"
              hide-details
              :loading="this.loadingTipsMatrix"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="border"
          >
            <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 3 '"
              v-model="form.tip_3"
              type="number"
              :min="0"
              :max="100"
              suffix="%"
              :loading="this.loadingTipsMatrix"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="border text-center align-center d-flex"
          >
            <strong class="m-auto text-muted">
              {{ this.$t('generic.lang_free_input') }}
            </strong>
            <!--  <v-text-field
              dense
              outlined
              :label="this.$t('generic.lang_option') + ' 1 '"
              type="number"
              :min="0"
              readonly
              placeholder=""
              disabled
              suffix="€"
              hide-details
              :loading="this.loadingTipsMatrix"
            ></v-text-field> -->
          </v-col>

          <v-col
            cols="12"
            class="px-0"
          >
            <v-btn
              color="primary"
              class="mx-0"
              block
              depressed
              @click="updateTipDialogMatrix"
              :loading="this.loadingTipsMatrix"
            >
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- payments -->
      <v-col
        cols="12"
        md="6"
      >
        <v-row class="mx-0 p-2">
          <v-col
            class="mx-0 px-0 py-1"
            cols="12"
          >
            <v-card-title class="px-0">
              {{ $t('accounting.lang_paymentOptions') }}
            </v-card-title>
          </v-col>
          <v-col
            v-if="this.loadingPaymentMethods"
            cols="12"
          >
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-col>
          <v-col
            v-for="method in paymentMethods"
            cols="12"
            md="6"
            :key="method.id"
            class="pt-0"
          >
            <v-switch
              :label="$t(method.name)"
              v-model="method.enabled"
              hide-details
              @change="updatePaymentMethod(method)"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>

      <!-- cashiers -->
      <v-col
        cols="12"
        class="border-top"
      >
        <v-card-title class="px-0">
          {{ $t('generic.lang_enable_tip_dialog_for_cashiers') }}
        </v-card-title>

        <v-select
          v-model="tipCashierIDs"
          dense
          :items="filteredCashierIDs"
          item-text="text"
          item-value="value"
          :label="$t('generic.lang_cashierID')"
          multiple
          outlined
          :loading="this.loadingCashiers"
          :disabled="this.loadingCashiers"
          @change="updateActiveCashiers"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import { debounce } from 'lodash';
export default {
  name: 'TipDialogSetting',
  data() {
    return {
      ENDPOINTS,
      Events,
      form: {
        tip_1: 0,
        tip_2: 0,
        tip_3: 0,
      },
      loadingTipsMatrix: false,
      loadingPaymentMethods: false,
      paymentMethods: [],
      cashierIDs: [],
      tipCashierIDs: [],
      loadingCashiers: false,
    };
  },
  computed: {
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierData) => {
        return {
          value: cashierData[0],
          text:
            'Kasse ' +
            cashierData[0] +
            (cashierData[1] && cashierData[1].length > 0
              ? ' ( ' + cashierData[1].replace(',', ' ') + ' ) '
              : ''),
        };
      });
    },
  },
  methods: {
    getCashiers() {
      this.loadingCashiers = true;

      this.axios
        .post(ENDPOINTS.GENERIC.CASHIERIDS.GET)
        .then((res) => {
          this.cashierIDs = res.data.cashierIDs;
        })
        .finally(() => (this.loadingCashiers = false));
    },
    async getActiveCashiers() {
      this.loadingCashiers = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.ACTIVE_CASHIERS.GET)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.tipCashierIDs = res.data.data ?? [];
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingCashiers = false;
        });
    },
    async updateActiveCashiers() {
      debounce(async () => {
        this.loadingCashiers = true;

        this.axios
          .post(
            ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.ACTIVE_CASHIERS.UPDATE,
            {
              tipCashierIDs: this.tipCashierIDs,
            }
          )
          .then((res) => {
            if (res.data.status) {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_success'),
                color: 'success',
              });
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingCashiers = false;
          });
      }, 500)();
    },
    async getTipMatrix() {
      this.loadingTipsMatrix = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.TIPS_MATRIX.GET)
        .then((res) => {
          if (res.data.success) {
            if (res.data.values) {
              this.form = { ...res.data.values };
            }
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingTipsMatrix = false;
        });
    },
    async updateTipDialogMatrix() {
      this.loadingTipsMatrix = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.TIPS_MATRIX.UPDATE,
          this.form
        )
        .then((res) => {
          if (res.data.status) {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingTipsMatrix = false;
        });
    },
    async getPaymentMethods() {
      this.loadingPaymentMethods = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.PAYMENT_METHODS.GET)
        .then((res) => {
          if (res.data.success) {
            this.paymentMethods = res.data.data ?? [];
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingPaymentMethods = false;
        });
    },
    async updatePaymentMethod(method) {
      this.loadingPaymentMethods = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.PAYMENT_METHODS.UPDATE,
          {
            paymentId: method.id,
            status: method.enabled,
          }
        )
        .then((res) => {
          if (res.data.status) {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingPaymentMethods = false;
        });
    },
  },
  mounted() {
    const promises = [
      this.getTipMatrix(),
      this.getPaymentMethods(),
      this.getCashiers(),
      this.getActiveCashiers(),
    ];

    Promise.all(promises);
  },
};
</script>
