<template>
  <v-container fluid>
    <v-form lazy-validation ref="form">
      <v-row class="mx-0">
        <v-col v-if="loading" cols="12">
          <v-progress-linear
            indeterminate
            color="primary"
            height="5"
          ></v-progress-linear>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-switch
            v-model="form.tip_customer_display_enabled"
            :label="$t('settings.lang_enableTipForCustomerDisplay')"
            hide-details
          ></v-switch>
        </v-col>

        <v-col cols="12" class="mt-4">
          <v-text-field
            v-model="form.tip_customer_display_text"
            :label="$t('settings.lang_tipCustomerDisplayText')"
            :data-layout="KEYBOARD.KEYSETS.NORMAL"
            @focus="showTouchKeyboard"
            dense
            outlined
            counter="200"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-card-title class="px-0">
            {{ $t("settings.lang_enableTipCustomerDisplayForTheseCashiers") }}
          </v-card-title>

          <v-select
            v-model="form.tip_customer_display_cashiers"
            :items="filteredCashierIDs"
            item-text="text"
            item-value="value"
            :loading="loadingCashiers"
            :disabled="loadingCashiers"
            :label="$t('generic.lang_cashierID')"
            multiple
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col class="text-right" cols="12">
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="updateTipSetting"
          >
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "CustomerDisplayTip",
  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      loadingCashiers: false,
      cashierIDs: [],
      form: {
        tip_customer_display_enabled: false,
        tip_customer_display_text: "",
        tip_customer_display_cashiers: [],
      },
    };
  },
  computed: {
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierData) => {
        return {
          value: cashierData[0],
          text: "Kasse " + cashierData[0] + (cashierData[1] && cashierData[1].length > 0
              ? " ( " + cashierData[1].replace(",", " ") + " ) "
              : ""),
        };
      });
    },
  },
  methods: {
    async getTipSetting() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.CUSTOMER_DISPLAY.GET)
        .then((res) => {
          if (res.data.success) {
            this.form = {
              tip_customer_display_enabled: res.data.tip_customer_display_enabled,
              tip_customer_display_text: res.data.tip_customer_display_text,
              tip_customer_display_cashiers: res.data.tip_customer_display_cashiers,
            };
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateTipSetting() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.TIP_MANGER.CUSTOMER_DISPLAY.UPDATE,
          this.form
        )
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_savedSuccessfully"),
              color: "success",
            });
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCashiers() {
      this.loadingCashiers = true;

      this.axios
        .post(ENDPOINTS.GENERIC.CASHIERIDS.GET)
        .then((res) => {
          this.cashierIDs = res.data.cashierIDs;
        })
        .finally(() => (this.loadingCashiers = false));
    },
  },
  mounted() {
    const promises = [this.getTipSetting(), this.getCashiers()];

    Promise.all(promises);
  },
};
</script>
